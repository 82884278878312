<app-header mainTitle="Book an Appointment"></app-header>
<main id="main">
    <section class="schedule-flow">
        <div class="container grid">
            <!--Breadcrumbs-->
            <app-breadcrumbs class="breadcrumbs-container"></app-breadcrumbs>

            <app-mobile-menu class="user-head mobile-head"></app-mobile-menu>

            <div class="content-container">
                <ul class="schedule-filters">
                    <!--Location-->
                    <!-- Updated HTML template -->
                    <li class="location-filter">
                        <button class="filter-button" 
                        (click)="toggleLocationFilter = !toggleLocationFilter; 
                        toggleStaffFilter = false; 
                        toggleTimeFilter = false; addBodyClass();">
                            Location <span class="counter">1</span>
                        </button>

                        <div class="filter-pop" [ngStyle]="{'display': toggleMobilePreferenceEsthetician ? 'none' : 'none'}">
                            <h3 class="flex-container eyebrow small">
                                <span class="mobile-only">esthetician </span>
                                <button class="close-filter" (click)="toggleMobilePreferenceEsthetician = !toggleMobilePreferenceEsthetician; 
                                toggleStaffFilter = false; 
                                toggleTimeFilter = false; addBodyClass();">
                                    <span class="accessibility">Close filter</span>
                                </button>
                            </h3>
                            <div class="esthetician-preference" *ngIf="toggleMobilePreferenceEsthetician">
                                <!-- Horizontal tabs -->
                               
                                <h4> Do you have a preference for your esthetician?</h4>

                                <ul class="content">
                                    <li class="flex-container" (click)="pickMyEsthetician()">
                                            <h4>
                                                <span>Yes, let me pick my esthetician</span>
                                            </h4>
                                            <a (click)="pickMyEsthetician()" class="btn black small" href="javascript:void();">Esthetician</a>
                                    </li>
                                    <li class="flex-container" (click)="pickAnyEsthetician()">
                                            <h4>
                                                <span>No, I'm happy with any esthetician</span>
                                            </h4>
                                            <a (click)="pickAnyEsthetician()" class="btn black small" href="javascript:void();">Esthetician</a>
                                    </li>
                                </ul>

                            </div>
                            
                        </div>






                        <div class="filter-pop" [ngStyle]="{'display': toggleLocationFilter ? 'block' : 'none'}">
                            <h3 class="flex-container eyebrow small">
                                <span class="mobile-only">Filter</span> Location
                                <button class="close-filter" (click)="toggleLocationFilter = !toggleLocationFilter; 
                                toggleStaffFilter = false; 
                                toggleTimeFilter = false; addBodyClass();">
                                    <span class="accessibility">Close filter</span>
                                </button>
                            </h3>
                            <div class="location-tabs" *ngIf="toggleLocationFilter">
                                <!-- Horizontal tabs -->
                               
                                <ul class="tabs">
                                    <li *ngFor="let location of locations | locationGroupSchedule :'city': this.bookingService.activeLocationByService$.value; let i = index;">
                                      <button [ngClass]="location.key === locationTab ? 'active' : ''" 
                                              (click)="expand(location.key)" 
                                              class="pill">{{ location.key }}</button>
                                    </li>
                                  </ul>
                                  
                                  <!-- Content associated with the selected tab -->
                                  <div *ngFor="let location of locations | locationGroupSchedule :'city': this.bookingService.activeLocationByService$.value; let i = index;"
                                       [ngClass]="{ 'visible': location.key === locationTab }" 
                                       class="notvisible">
                                    <ul class="x-small">
                                      <li [ngClass]="locationSelected(loc.id)" *ngFor="let loc of location.value">
                                        <ng-container>
                                          <input type="radio" name="location" id="location-{{ loc.name }}" class="location-radio" 
                                                 [checked]="selectedLocation === loc.id" 
                                                 (click)="changeLocation(loc.id)" />  
                                          <label for="location-{{ loc.name }}">
                                            <strong>{{ loc.name }}</strong> | {{loc.address?.line1}}
                                          </label>
                                        </ng-container>
                                        <!-- <ng-container *ngIf="!loc.enabled">
                                          <input style="color: #ccc;" disabled type="radio" name="location" id="location-{{ loc.name }}" class="location-radio" 
                                                 [checked]="selectedLocation === loc.id" />  
                                          <label style="color: #ccc;" for="location-{{ loc.name }}">
                                            <strong>{{ loc.name }}</strong> | {{loc.address?.line1}}
                                          </label>
                                        </ng-container> -->
                                      </li>
                                    </ul>
                                  </div>
                                  
                                  <button class="btn black submit-btn mobile-only">Apply</button>
                                  
                            </div>
                            
                        </div>
                    </li>


                    <!--Time-->

                    <li class="time-filter">
                        <button class="filter-button"
                            (click)="toggleTimeFilter = !toggleTimeFilter; toggleLocationFilter = false; toggleStaffFilter = false ; addBodyClass();">Time <span
                                class="counter">0</span></button>
                        <div class="popup-screen" [ngStyle]="{'display':toggleTimeFilter ? 'block' : 'none'}"></div>
                        <div class="filter-pop" [ngStyle]="{'display':toggleTimeFilter ? 'block' : 'none'}">
                            <h3 class="flex-container eyebrow small">
                                <span class="mobile-only">Filter</span> Time
                                <button class="close-filter" (click)="toggleTimeFilter = !toggleTimeFilter; toggleLocationFilter = false; toggleStaffFilter = false ; addBodyClass();"><span
                                        class="accessibility">Close filter</span></button>

                            </h3>

                            <div class="range-slider" *ngIf="toggleTimeFilter">
                                <ngx-slider (userChangeEnd)="changeTimeRange($event)" [(value)]="minValue"
                                    [(highValue)]="maxValue" [options]="options"></ngx-slider>
                                <button class="text-link clear-time-range mobile-only"
                                    (click)="clearTimeFilter()">Clear</button>
                            </div>

                            <button class="btn black submit-btn mobile-only"
                                (click)="closeMobileFilter()">Apply</button>
                        </div>
                    </li>

                    <!-- New Esthetician -->
                    <li style="display: none;" class="esthetician-filter" *ngIf="staffList?.value?.length && !cart?.guests?.length">
                        <button class="filter-button" (click)="toggleStaffFilter = !toggleStaffFilter; 
                        toggleLocationFilter = false; toggleTimeFilter = false;  addBodyClass();">
                            Esthetician <span class="counter">1</span></button>

                        <div class="filter-pop" [ngStyle]="{'display': toggleStaffFilter ? 'block' : 'none'}">
                            <h3 class="flex-container eyebrow small">
                                <span class="mobile-only">Esthetician</span>
                                <span class="desktop-only">Type Esthetician Name</span>
                                <button class="close-filter" (click)="toggleStaffFilter = !toggleStaffFilter; 
                                toggleLocationFilter = false; toggleTimeFilter = false;  addBodyClass();"><span class="accessibility">Close filter</span></button>
                            </h3>
                            <div class="searchStaff">
                                <span class="mobile-only">Type Esthetician Name</span>
                                <input type="text" [(ngModel)]="filterText" placeholder="Type Here"/>
                            </div>

                            <div class="esthetician-selected-list">
                                <ngx-simplebar >
                                    <ul class="grid" *ngIf="getSelectedStaffList().length">
                                        <li *ngFor="let staff of getSelectedStaffList() | staffFilter:filterText | orderEstheticianByName">
                                            <label class="selected-esthetician">{{staff?.firstName+ ' '
                                                +staff?.lastName[0]+'.'}} <span *ngIf="staff?.recent" class="esthetician-recent"></span><span *ngIf="staff?.preferred" class="esthetician-preferred"></span> <span style="float: right;" (click)="unselectSelectedStaff(staff)">X</span></label>
                                        </li>
                                    </ul>
                                    <ul class="grid" *ngIf="!getSelectedStaffList().length">
                                        <li>
                                            <label class="selected-esthetician"><span *ngIf="cart?.guests?.length">Esthetician :
                                                </span>Any</label>
                                        </li>
                                    </ul>
                                </ngx-simplebar>
                            </div>

                            <div class="esthetician-list">
                                <ngx-simplebar >
                                    <ul class="grid">
                                        
                                        <li *ngFor="let staff of staffData | staffFilter:filterText | orderEstheticianByName">
                                            <input [(ngModel)]="staff.checked" [checked]="staff.checked" [id]="'filter-'+staff.id" class="accessibility" type="checkbox" (change)="staffSelectionChange($event)">
                                            <label [for]="'filter-'+staff.id">{{staff.firstName+ ' ' +staff.lastName[0]+'.'}} <span *ngIf="staff?.recent" class="esthetician-recent"></span><span *ngIf="staff?.preferred" class="esthetician-preferred"></span></label>
                                        </li>
                    
                                    </ul>
                                </ngx-simplebar>
                            </div>
                            <button style="bottom: auto;" class="btn black submit-btn mobile-only" (click)="closeEstheticianFilter()">Apply</button>
                        </div>
                    </li>
                    <!-- <span *ngIf="getSelectedStaffList().length">
                        <li *ngFor="let staff of getSelectedStaffList()">
                            <label class="selected-esthetician">{{staff?.firstName+ ' '
                                +staff?.lastName[0]+'.'}}</label>
                        </li>
                    </span>
                    <span *ngIf="!getSelectedStaffList().length">
                        <li>
                            <label class="selected-esthetician"><span *ngIf="cart?.guests?.length">Esthetician :
                                </span>Any</label>
                        </li>
                    </span> -->

                    <!-- old Esthetician -->

                    <!-- <li class="ng-autocomplete esthetician-filter"
                        *ngIf="staffList?.value?.length && !cart?.guests?.length">
                        <ng-autocomplete [data]="staffData" searchKeyword="firstName" placeholder="Esthetician"
                            (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)'
                            (inputCleared)="clearStaffFilter(false)" [itemTemplate]="staffTemplate"
                            [notFoundTemplate]="notFoundTemplate">
                        </ng-autocomplete>

                        <ng-template #staffTemplate let-item>
                            <a [innerHTML]="item.firstName+ ' ' +item.lastName[0]+'.'"></a>
                        </ng-template>

                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </li>
                    <li *ngIf="getSelectedStaff()?.firstName">
                        <label class="selected-esthetician">{{getSelectedStaff()?.firstName+ ' '
                            +getSelectedStaff()?.lastName[0]+'.'}}</label>
                    </li>
                    <li *ngIf="!getSelectedStaff()?.firstName">
                        <label class="selected-esthetician"><span *ngIf="cart?.guests?.length">Esthetician :
                            </span>Any</label>
                    </li> -->
                </ul>


                <p *ngIf="!availableTimes.value?.length" class="x-small no-availability">Please select an appointment date.</p>
                <app-calendar [toggleFullCalendar]="availableDates.value.length ? true : false" [selectedDate]="selectedDate"
                    [availableDates]="availableDates | async" (changeMonthEvent)="monthChange($event)"
                    (dateSelectEvent)="selectDate($event)"></app-calendar>


                <p class="x-small no-availability" *ngIf="!(availableTimes | async).length && !(allStaffAvailableTimes | async).length">
                    If you see no available times, please adjust your filters.
                </p>
                <!-- <div style="text-align: center;" *ngIf="!(allStaffAvailableTimes | async).length && loadingTime">
                    <img style="width: 65px;" src="assets/Iphone-spinner.gif"> </div> -->
                <div class="time-slots">

                    <div class="time-slot-container" *ngIf="(allStaffAvailableTimes | async).length">
                        <h2 class="heading-6 small">Available Time</h2>
                        <p style="position: sticky; left:0;" *ngIf="!staffVarients.value?.length" class="xx-small info">
                            Please select an appointment time.</p>
                            <div *ngFor="let data of allStaffAvailableTimes | async | orderEstheticianByBio">
                                <div *ngIf="data.availableTimes.length">
                                  <!-- -->
                                  <div class="staff-outer">
                                        <p>
                                        {{ data?.staff?.firstName + ' ' + data?.staff?.lastName[0] + '.' }} 
                                        <span *ngIf="getTextBio(data?.staff?.bio)" class="staff-icon" (click)="toggleBioStatus(activeStaffClass[data?.staff?.id] ? null : data?.staff?.id)" 
                                        [ngClass]="{'active': activeStaffClass[data?.staff?.id]}">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" fill="currentColor" class="bi bi-person-fill" viewBox="0 0 16 16">
                                            <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6"/>
                                            </svg>
                                        </span>
                                        
                                        </p>

                                        <!-- Popup for Bio -->
                                        <div class="staff-bio-popup" *ngIf="isBioActive(data?.staff?.id)">
                                            <div class="staff-bio-content">
                                                <div class="staff-name">{{ data?.staff?.firstName + ' ' + data?.staff?.lastName[0] + '.' }} 
                                             
                                                    <div *ngIf="getBadgeBio(data?.staff?.bio)[0]" class="badge" [ngClass]="getBadgeBio(data?.staff?.bio)[0]">
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                            <path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0z"/>
                                                        </svg>
                                                        <span>{{getBadgeBio(data?.staff?.bio)[1]}}</span>
                                                    </div>
                
                                                    <!-- ROLE -->
                                                    <div class="badge role">
                                                        <div class="role-inner">
                                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                                <path d="M256 0c4.6 0 9.2 1 13.4 2.9L457.7 82.8c22 9.3 38.4 31 38.3 57.2c-.5 99.2-41.3 280.7-213.6 363.2c-16.7 8-36.1 8-52.8 0C57.3 420.7 16.5 239.2 16 140c-.1-26.2 16.3-47.9 38.3-57.2L242.7 2.9C246.8 1 251.4 0 256 0z"/>
                                                            </svg>
                                                            <span class="e-role">{{getRoleBio(data?.staff?.bio)}}</span>
                                                        </div>
                                                    </div>

                                                </div> 
                                                <span [innerHTML]="getTextBio(data?.staff?.bio)"></span>
                                                <button class="close-btn" (click)="toggleBioStatus(null)"><span class="accessibility">Close</span></button>
                                            </div>
                                        </div>
                                    </div>
                                    <!---->

                                  <fieldset class="flex-container">
                                    <span style="display: flex;" *ngFor="let time of data.availableTimes | timeFilter:{min:minValue, max:maxValue}">
                                      <input
                                        (change)="selectTimeMe(time, data.staff)"
                                        [id]="time?.id + '-' + data.staff.id"
                                        [checked]="isSelected(time.id,data.staff.id)"
                                        class="accessibility"
                                        name="time-chooser"
                                        type="radio"
                                      />
                                      <label [for]="time?.id + '-' + data.staff.id">{{ sharedService.removeLastTimezone(time?.startTime) | date:'hh:mm a' }}</label>
                                    </span>
                                  </fieldset>
                                </div>
                              </div>
                    <div *ngIf="loadMoreStatus">
                        <button style="display: none;" *ngIf="!loadMore" class="btn black" #loadMoreButton (click)="processRemainingBatches()">Load more</button>
                        <div *ngIf="loadMore" class="loader" [ngStyle]="" style="width: 30px;">
                         <img src="assets/Iphone-spinner.gif">
                        </div>  
                    </div>        
                    </div>

                    <div class="time-slot-container" *ngIf="(availableTimes | async).length">
                        <h2 class="heading-6 small">Available Time</h2>
                        <p style="position: sticky; left:0;" *ngIf="!staffVarients.value?.length" class="xx-small info">
                            Please select an appointment time.</p>
                        <fieldset class="flex-container">
                            <span style="display: flex;"
                                *ngFor="let time of availableTimes | async | timeFilter:{min:minValue, max:maxValue}">
                                <input (change)="selectTime(time)" [id]="time?.id" [checked]="time.selected"
                                    class="accessibility" name="time-chooser" type="radio" />
                                <label [for]="time?.id">{{sharedService.removeLastTimezone(time?.startTime) |
                                    date:'hh:mm a'}}</label>
                            </span>
                        </fieldset>
                    </div>
                </div>
                <div class="time-slots available-staff" *ngIf="cart?.guests?.length && this.bookingService.bookableTimeId">
                    <div class="time-slot-container" *ngIf="(staffVarients | async).length">
                        <h2 class="heading-6 small">Esthetician Preference</h2>

                        <ul class="esthetician-pref-bottom">
                            <li>
                                <span class="label">Me</span>
                                <div class="custom-select">
                                    <select (change)="variantSelection($event, 'me')">
                                        <option value="any">Any Esthetician</option>
                                        <option [value]="variant.id" [disabled]="isVariantDisabled(variant, 'me')" *ngFor="let variant of staffVarients | async">{{variant?.staff?.firstName}} {{variant?.staff?.lastName[0]}}</option>
                                    </select>
                                </div> 
                            </li>
                            <li *ngFor="let guest of cart?.guests | sortGuests">
                                <span class="label">{{guest?.label}}</span>
                                <div class="custom-select">
                                    <select (change)="variantSelection($event, guest)">
                                        <option value="any">Any Esthetician</option>
                                        <option [value]="variant.id" [disabled]="isVariantDisabled(variant, guest.label)" *ngFor="let variant of staffVarients | async">{{variant?.staff?.firstName}} {{variant?.staff?.lastName[0]}}</option>
                                    </select>
                                </div>
                            </li>
                        </ul>

                        <!-- <fieldset class="flex-container esthetician-pref" name="esthetician-pref" ngDefaultControl
                            (change)="changeEstheticianPreference()" [(ngModel)]="estheticianPref">
                            <span style="display: inline-block;">
                                <input id="any" [checked]="estheticianPref == 1" class="" name="esthetician-pref"
                                    value="1" type="radio" />
                                <label for="any">Any esthetician</label>
                            </span>
                            <span style="display: inline-block;">
                                <input id="my-esthetician" [checked]="estheticianPref == 2" class=""
                                    name="esthetician-pref" value="2" type="radio" />
                                <label for="my-esthetician">I want to select my esthetician</label>
                            </span>
                        </fieldset>

                        <fieldset class="flex-container" *ngIf="estheticianPref == 2">
                            <span style="display: flex;" *ngFor="let staff of staffVarients | async | orderEsthetician">
                                <input *ngIf="!staff.filter" (change)="selectStaff(staff)" [id]="staff?.id"
                                    [(checked)]="staff.selected" class="accessibility" name="staff-chooser"
                                    type="radio" />
                                <label *ngIf="!staff.filter" [for]="staff?.id">{{staff?.staff?.firstName}}</label>
                            </span>
                        </fieldset> -->
                    </div>
                </div>
                <div class="continue-btn mobile-only">
                    <a class="btn" (click)="reserveCart()">Continue</a>
                    <!-- <a class="btn" (click)="bookingService.toggleMobileCart()">Continue Mobile {{this.bookingService.toggleMobileCartReview}}</a> -->
                </div>
            </div>

            <!-- <aside [ngClass]="!cart?.selectedItems?.length ? 'empty':'' ">
                <app-cart [cart]="cart"></app-cart>
                <a class="btn desktop-only" (click)="reserveCart()">Continue</a>
            </aside> -->

            <aside *ngIf="cart" [ngClass]="(bookingService.mobileCartView | async) ? 'active' : '' " >
                <div class="sidebar-sticky-desktop-only">
                    <app-cart [cart]="cart"></app-cart>
                    <a class="btn desktop-only" (click)="reserveCart()">Continue</a>
                </div>
            </aside>

        </div>
    </section>
</main>
<app-footer></app-footer>